// extracted by mini-css-extract-plugin
export const root = "header-module--root--2Pllp";
export const wrapper = "header-module--wrapper--182n5";
export const logoSvg = "header-module--logoSvg--39PTd";
export const branding = "header-module--branding--1PjA2";
export const toggleNavButton = "header-module--toggleNavButton--34Tf4";
export const nav = "header-module--nav--106zH";
export const showNav = "header-module--showNav--CuuOZ";
export const navMobileContent = "header-module--navMobileContent--3d9LO";
export const mobileNavLink = "header-module--mobileNavLink--wPvj3";
export const mobileNavLanguageLink = "header-module--mobileNavLanguageLink--2kuCA";